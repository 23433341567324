import { type FC, type ReactElement } from 'react'

import {
  Tooltip,
  TreeItem,
  Typography,
  type TreeItemProps
} from '@matillion/component-library'

import { useImportPipelines } from 'api/hooks/useImportPipelines/useImportPipelines'

import Folder from 'components/Folder/Folder'
import { PopOverMenu } from 'components/PopOverMenu'

import type { FileNode } from 'modules/FileBrowser/reducers/fileTreeReducer/types'

import ContextMenuButton from '../ContextMenuButton/ContextMenuButton'
import FileFolderDropDestination, {
  type FileDropDetails
} from '../FileFolderDropDestination/FileFolderDropDestination'
import FolderItemContextMenu from '../FolderItemContextMenu/FolderItemContextMenu'
import classes from './FileFolder.module.scss'

export interface FileFolderProps {
  id: string
  name: string
  node: FileNode
  children?: ReactElement<TreeItemProps> | Array<ReactElement<TreeItemProps>>
  isEmpty: boolean
  moveFile: (details: FileDropDetails) => void
}

const FileFolder: FC<FileFolderProps> = ({
  id,
  name,
  node,
  children,
  isEmpty,
  moveFile
}) => {
  const { handleImportPipelines, ImportInputField } = useImportPipelines(id)

  return (
    <>
      <ImportInputField />
      <TreeItem
        key={id}
        nodeId={id}
        label={id}
        leaf={
          <FileFolderDropDestination
            id={id}
            key={id}
            onDropPipeline={(dropDetails) => {
              moveFile({ ...dropDetails, targetDirectory: id })
            }}
          >
            <PopOverMenu
              key={id}
              content={
                <FolderItemContextMenu
                  path={id}
                  node={node}
                  handleImportPipelines={handleImportPipelines}
                  isEmpty={isEmpty}
                />
              }
            >
              {({ onClick }) => {
                return (
                  <div
                    className={classes.Folder}
                    data-testid={`pipeline-folder-${id}`}
                  >
                    <Folder className={classes.FolderIcon} />

                    <Tooltip content={name} onlyShowOnOverflow>
                      <div className={classes.TextContainer}>
                        <Typography
                          as="span"
                          format="bcs"
                          className={classes.Text}
                        >
                          {name}
                        </Typography>
                      </div>
                    </Tooltip>

                    <ContextMenuButton id={id} key={id} onClick={onClick} />
                  </div>
                )
              }}
            </PopOverMenu>
          </FileFolderDropDestination>
        }
      >
        {children}
      </TreeItem>
    </>
  )
}

export default FileFolder
