import { useState, type ChangeEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Field,
  Typography,
  type AutoCompleteItem,
  type AutoCompleteItemId
} from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg'

import { AutoComplete } from 'components/AutoComplete'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { type ParameterOptions } from 'modules/ParameterEditors/types'

import {
  getProjectExplorerLink,
  ProjectExplorerPaths
} from 'utils/getProjectExplorerLink'

import classes from './SecretReferenceEditor.module.scss'

export interface SecretReferenceEditorProps extends ParameterOptions {
  parameterName: string
  parameterId: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
  allowFreetext?: boolean
  displayInline: boolean
}

export const SecretReferenceEditor: FC<SecretReferenceEditorProps> = ({
  value,
  parameterName,
  parameterId,
  onDone,
  editorColumns,
  allowFreetext = true,
  displayInline
}) => {
  const { t } = useTranslation()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { job } = useFlaggedWorkingCopy()
  const { projectId } = useProjectInfo()
  const [secret, setSecret] = useState<AutoCompleteItemId | null>({
    name: value[0],
    id: value[0]
  })
  const [isValidSecretRef, setIsValidSecretRef] = useState<boolean>(true)

  const { refetch, isFetching: isValidatingSecret } = useGetParameterOptions({
    parameterId,
    requestType: 'parameter-options',
    lookupType: LookUpType.DYNAMIC_JAVASCRIPT_EXECUTION,
    variables: job?.variables ?? {},
    gridVariables: job?.grids ?? {},
    lookupDefinition: {
      scriptsToEvaluate: secret ? [secret.name] : undefined
    },
    isEnabled: false
  })

  const onChooseSecret = () => {
    const validateSecret = async () => {
      setIsValidSecretRef(true)
      const validationResult = await refetch()
      const secretReferences = editorColumns[0]?.options
      const isValid =
        secretReferences?.some((ref) =>
          validationResult.data?.editorColumns[0].options?.includes(ref)
        ) ?? true
      setIsValidSecretRef(isValid)

      if (isValid && secret) {
        return onDone([secret.name])
      }
    }

    if (secret?.name) {
      validateSecret()
    } else {
      onDone([])
    }
  }

  const title = t(
    `parameterEditor.SECRET_REFERENCE.${displayInline ? 'title' : 'oldTitle'}`
  )

  return (
    <>
      {!displayInline && <ParameterOverlayHeader title={parameterName} />}
      <ParameterOverlayContent>
        <Field
          id="secret"
          name="secret"
          aria-label={title}
          placeholder={title}
          title={title}
          allowFreetext={allowFreetext}
          inputComponent={AutoComplete}
          value={secret}
          availableItems={(editorColumns[0]?.options ?? []).map((option) => ({
            name: option,
            id: option
          }))}
          onChange={(e: ChangeEvent<AutoCompleteItem>) => {
            setSecret(e.target.value)
          }}
          onBlur={() => {
            // once an option has been chosen clear down any existing validation message
            // the form will be revalidated whenever the user presses the save button
            if (!displayInline) {
              setIsValidSecretRef(true)
            } else {
              onChooseSecret()
            }
          }}
          hasError={!isValidSecretRef}
          errorText={
            !isValidSecretRef &&
            t('parameterEditor.SECRET_REFERENCE.invalidSecretReferenceError')
          }
          fixAutocompletePosition
        />

        <div className={classes.SecretReferenceEditor__LinkContainer}>
          <a
            data-testid={`secret-reference-editor-${parameterName}-secret-link`}
            className={classes.SecretReferenceEditor__ManageLink}
            href={getProjectExplorerLink(
              projectExplorerOrigin,
              projectId,
              ProjectExplorerPaths.SECRET_DEFINITIONS
            )}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography format="bcs">
              {t('parameterEditor.SECRET_REFERENCE.manage')}
            </Typography>
            <ExternalLinkIcon />
          </a>
        </div>
      </ParameterOverlayContent>
      {!displayInline && (
        <ParameterOverlayFooter>
          <ParameterOverlayButton
            onClick={() => {
              onChooseSecret()
            }}
            text={t('common.save')}
            waiting={isValidatingSecret}
          />
        </ParameterOverlayFooter>
      )}
    </>
  )
}
