import useCanRunComponent from 'hooks/useCanRunComponent/useCanRunComponent'
import useGetSelectedComponent from 'hooks/useGetSelectedComponent/useGetSelectedComponent'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import {
  type ContextMenuItem,
  type ContextMenuSection
} from '../CanvasContextMenu'

interface UseRunComponentSectionProps {
  jobType: JobType | undefined
}

export const useRunComponentSection = ({
  jobType
}: UseRunComponentSectionProps): ContextMenuSection => {
  const { summaryId } = useGetSelectedComponent()
  const { componentId: componentInstanceId } = useProjectInfo()
  const { canRunFromComponent, canRunOnlyComponent, componentLabel } =
    useCanRunComponent(componentInstanceId, summaryId)

  const items: ContextMenuItem[] = []

  if (jobType !== JobType.Orchestration) {
    return {
      id: 'runComponent',
      items
    }
  }

  if (canRunOnlyComponent) {
    items.push({
      id: 'runOnlyComponent',
      subtext: ` (${componentLabel})`
    })
  }

  if (canRunFromComponent) {
    items.push({
      id: 'runFromComponent',
      subtext: ` (${componentLabel})`
    })
  }

  return {
    id: 'runComponent',
    items
  }
}
