import { useEffect } from 'react'

import {
  type OrchestrationJob,
  type TransformationJob
} from '../../../../../../../job-lib/types/Job'
import { useSyncedCanvasModel } from './useSyncedCanvasModel'

export const useInitialiseCanvasHandlers = (
  pipeline: TransformationJob | OrchestrationJob
) => {
  const { syncCanvasModel } = useSyncedCanvasModel(pipeline)

  useEffect(() => {
    syncCanvasModel() // Call sync on initialization
  }, [syncCanvasModel])
}
