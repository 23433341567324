import {
  componentOutputConnectorFields,
  getAllJobConnections,
  getAllNonIterationOutputConnections,
  isConnectorWithType,
  jobConnectorFields,
  makeConnectorId
} from 'job-lib/job-functions/job-functions'
import { deleteOrchestrationLink } from 'job-lib/store/jobSlice/reducers/deleteLink/deleteOrchestrationLink'
import { Cardinality, OutputPortType } from 'job-lib/types/Components'
import {
  type ComponentInstanceId,
  type Connector,
  type OrchestrationJob
} from 'job-lib/types/Job'

import { type UpdateLinks } from '../store/jobSlice/job.types'
import { unique } from '../store/jobSlice/utils/unique'

/**
 * Move connections from the target component to the new source component, used to preserve connections on iterators
 * N.b. Ignores cardinality and will blindly move connections.
 */
const moveInputToSource = (
  job: OrchestrationJob,
  targetComponentId: ComponentInstanceId,
  sourceComponentId: ComponentInstanceId
) => {
  const targetComponent = job.components[targetComponentId]
  const sourceComponent = job.components[sourceComponentId]
  const inputConnectorIds = targetComponent.inputConnectorIDs
  const allConnectors = getAllJobConnections(job)

  const connectors = inputConnectorIds
    .map((inputConnectorId) =>
      allConnectors.find(({ id }) => id === inputConnectorId)
    )
    .filter(isConnectorWithType)

  // move existing input connectors from the target to the source
  connectors.forEach(({ outputType: type, ...connector }) => {
    // add existing connector to the source
    sourceComponent.inputConnectorIDs = unique(
      sourceComponent.inputConnectorIDs,
      connector.id
    )

    // remove from the old target
    targetComponent.inputConnectorIDs =
      targetComponent.inputConnectorIDs.filter(
        (idInComponent) => idInComponent !== connector.id
      )

    // update connector
    job[jobConnectorFields[type]] = {
      ...job[jobConnectorFields[type]],
      [connector.id]: {
        ...connector,
        // target now points to the new (iterator) component
        targetID: sourceComponentId
      }
    }
  })
}

/**
 * Move output connections from the target component to the new source component, used to preserve connections on iterators.
 * N.b. Ignores cardinality and will blindly move connections.
 */
const moveOutputsToSource = (
  job: OrchestrationJob,
  targetComponentId: ComponentInstanceId,
  sourceComponentId: ComponentInstanceId
) => {
  const allOutputConnectors = getAllNonIterationOutputConnections(
    job,
    targetComponentId
  )
  const targetComponent = job.components[targetComponentId]
  const sourceComponent = job.components[sourceComponentId]

  allOutputConnectors.forEach(({ outputType: type, ...connector }) => {
    // add to source output connections
    sourceComponent[componentOutputConnectorFields[type]] = unique(
      sourceComponent[componentOutputConnectorFields[type]],
      connector.id
    )

    // remove from target output connections
    targetComponent[componentOutputConnectorFields[type]] = targetComponent[
      componentOutputConnectorFields[type]
    ].filter((n) => n !== connector.id)

    // change job connector
    job[jobConnectorFields[type]] = {
      ...job[jobConnectorFields[type]],
      [connector.id]: {
        ...connector,
        sourceID: sourceComponentId
      }
    }
  })
}

/**
 * Given a target and/or a source, this function will return a connector which contains either or both IDs
 * If both target and source are passed, then what you're doing is effectively checking for an already
 * existing link.
 * @param job — Orchestration job
 * @param targetID — ID of the component's input used as target
 * @param sourceID — ID of the component's output used as source
 * @returns Connector | undefined
 */
const getOrchestrationExistingConnector = (
  job: OrchestrationJob,
  targetID?: ComponentInstanceId,
  sourceID?: ComponentInstanceId
) => {
  const connectorTypes = Object.keys(jobConnectorFields) as OutputPortType[]
  let existingConnector: Connector | undefined
  const cType = connectorTypes.find((_connectorType) =>
    Object.values(job[jobConnectorFields[_connectorType]]).some((connector) => {
      if (!targetID || connector.targetID === targetID) {
        if (!sourceID || connector.sourceID === sourceID) {
          existingConnector = connector
          return true
        }
      }
      return false
    })
  )
  return existingConnector && cType
    ? { ...existingConnector, type: cType }
    : undefined
}

export const updateOrchestrationLinks = ({
  job,
  sourceComponentId,
  targetComponentId,
  sourceCardinality,
  targetCardinality,
  sourceType
}: { job: OrchestrationJob } & UpdateLinks) => {
  const connectorId = makeConnectorId(job)

  if (sourceType === OutputPortType.ITERATION) {
    moveInputToSource(job, targetComponentId, sourceComponentId)
    moveOutputsToSource(job, targetComponentId, sourceComponentId)
  }

  /**
   * This enables us to check against the state of the target component, BEFORE we create a new link.
   * Important when the target has a cardinality of ONE, in which case the new link we're about to create
   * will have to remove/override this old connector against our target.
   */
  const connectorAgainstTarget = getOrchestrationExistingConnector(
    job,
    targetComponentId
  )

  /**
   * This enables us to know whether the link we're about to create already exists. Regardless of the
   * cardinality of both source/target, a new link will be created and this value will allow us to remove
   * the old duplicated link.
   */
  const duplicatedConnector = getOrchestrationExistingConnector(
    job,
    targetComponentId,
    sourceComponentId
  )
  /**
   * This enables us to check against the state of the source component, BEFORE we create a new link.
   * Important when the source has a cardinality of ONE, in which case the new link we're about to create
   * will have to remove/override this old connector against our source.
   */
  const connectorFromSource = getOrchestrationExistingConnector(
    job,
    undefined,
    sourceComponentId
  )

  /**
   * This returns the current list of link-IDs against our source's output. We'll have to add our new
   * link ID here
   */
  const outputConnectors =
    job.components[sourceComponentId][
      componentOutputConnectorFields[sourceType]
    ]
  const newSourceValue = unique(outputConnectors, connectorId)

  /**
   * This returns the current list of link-IDs against our target's input. We'll have to add our new
   * link ID here
   */
  const inputConnectors = job.components[targetComponentId].inputConnectorIDs
  const newTargetValue = unique(inputConnectors, connectorId)

  /** Updates source output list with unique list of IDs (previous and new one) */
  job.components[sourceComponentId][
    componentOutputConnectorFields[sourceType]
  ] = newSourceValue

  /** Updates target input list with unique list of IDs (previous and new one) */
  if (sourceType === OutputPortType.ITERATION) {
    job.components[targetComponentId].inputIterationConnectorIDs =
      newTargetValue
  } else {
    job.components[targetComponentId].inputConnectorIDs = newTargetValue
  }

  /** Creates a new connector under the correct source type (e.g. unconditional, success, etc) */
  job[jobConnectorFields[sourceType]][connectorId] = {
    id: connectorId,
    sourceID: sourceComponentId,
    targetID: targetComponentId
  }

  /**
   * If our target has cardinality of ONE, and before creating the new link there was already a link against it,
   * then it deletes that old link against our target
   */
  if (
    connectorAgainstTarget &&
    targetCardinality === Cardinality.ONE &&
    inputConnectors.length
  ) {
    deleteOrchestrationLink(connectorAgainstTarget.id, job)
  }

  /**
   * If our source has cardinality of ONE, and before creating the new link there was already a link against it,
   * then it deletes that old link against our source
   */
  if (
    connectorFromSource &&
    sourceCardinality === Cardinality.ONE &&
    outputConnectors.length
  ) {
    deleteOrchestrationLink(connectorFromSource.id, job)
  }

  /**
   * If there was a duplicated link already, it removes it. NB: for components with cardinality of ONE, this
   * operation might have already happened in one of the two previous IF statements. The following IF is particularly
   * useful for components without cardinality of ONE.
   */
  if (duplicatedConnector) {
    deleteOrchestrationLink(duplicatedConnector.id, job)
  }

  return job
}
