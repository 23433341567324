import { OutputPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export enum PipelineType {
  Orchestration = 'orchestration',
  Transformation = 'transformation'
}

export const DPLtoMELTPipelineType = {
  [PipelineType.Orchestration]: JobType.Orchestration,
  [PipelineType.Transformation]: JobType.Transformation
}

export type ComponentName = string
export type ParameterId = string
export type ComponentId = string
export type VariableName = string
export type NoteId = string

export enum Transition {
  iteration = 'iteration',
  unconditional = 'unconditional',
  success = 'success',
  failure = 'failure',
  true = 'true',
  false = 'false'
}

export const MELTtoDPLTransition = {
  [OutputPortType.ITERATION]: 'iteration',
  [OutputPortType.TRUE]: 'true',
  [OutputPortType.FALSE]: 'false',
  [OutputPortType.SUCCESS]: 'success',
  [OutputPortType.FAILURE]: 'failure',
  [OutputPortType.UNCONDITIONAL]: 'unconditional'
} as const

export type Transitions = {
  [key in Transition]?: ComponentName[]
}

export type ScalarValue = string
export type ListValue = string[]
export type GridValue = string[][]
export interface GridValueFromVariable {
  fromGrid: {
    variable: string
    columns: string[]
  }
}

export interface StructValue {
  [key: string]: ParameterValue
}

export type StructList = StructValue[]

export type ParameterValue =
  | ScalarValue
  | ListValue
  | GridValue
  | GridValueFromVariable
  | StructValue
  | StructList
  | null

export type Parameters = Record<ParameterId, ParameterValue>

export type Components = Record<ComponentName, Component>

export interface ComponentExport {
  /** Export source */
  source: string
  /** Variable to export the source value to. */
  targetVariable: string
}

export enum VariableType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Grid = 'GRID'
}

export interface Column {
  columnType: VariableType
}

export interface Position {
  x: number
  y: number
}

export interface Component {
  type: ComponentId
  /** Dynamic parameter configuration for a component */
  parameters: Parameters
  /** Transitions are only present in Orchestration pipelines */
  transitions?: Transitions
  /** Sources are only present in Transformation pipelines */
  sources?: ComponentName[]
  /** (Iterator only) Component to iterate over. */
  iterationTarget?: ComponentName
  /** Support exporting runtime information into variables during job execution */
  exports?: ComponentExport[]
}

export type VariableScope = 'COPIED' | 'SHARED'
export type VariableVisibility = 'PRIVATE' | 'PUBLIC'

/** Variables defined for the pipeline */
export interface ScalarVariable {
  /** Metadata that describes the variable */
  metadata: {
    type: VariableType
    scope: VariableScope
    visibility: VariableVisibility
    description?: string
  }
  /** Default value for the scalar variable */
  defaultValue: ScalarValue
}

/** Grid-based two-dimensional variable */
export interface GridVariable {
  /** Metadata that describes the variable */
  metadata: {
    type: VariableType.Grid
    scope: VariableScope
    visibility: VariableVisibility
    columns: Record<string, Column>
    description?: string
  }
  /** Default value for the grid variable */
  defaultValue: GridValue
}

export type NoteTheme = 'green' | 'yellow' | 'red' | 'white'

export interface Note {
  /** Markdown content */
  content: string
  position: Position
  size: {
    height: number
    width: number
  }
  theme: NoteTheme
  isAIGenerated?: boolean
  selection?: string[]
}

export interface ComponentDesign {
  position: Position
  tempMetlId?: number
}

export interface Pipeline {
  type: PipelineType
  /** Document version in "1.0" format */
  version: string
  pipeline: {
    components: Components
    variables: Record<VariableName, ScalarVariable | GridVariable>
  }
  design: {
    components: Record<ComponentName, ComponentDesign>
    notes: Record<NoteId, Note>
  }
}
