import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ComponentEditorTabType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import { ComponentParameterWizardContainer } from 'modules/ComponentParameters/ComponentParameterWizardContainer'
import { ComponentPostProcessingContainer } from 'modules/ComponentParameters/components/ComponentPostProcessingContainer/ComponentPostProcessingContainer'
import { SQLViewer } from 'modules/ComponentProperties/components/SQLViewer'

import classes from './ComponentTabContent.module.scss'

interface ComponentTabContentProps {
  tabType: ComponentEditorTabType
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
  onBlur: (params: EditedParameter) => void
}

export const ComponentTabContent: FC<ComponentTabContentProps> = ({
  tabType,
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'componentProperties.tabs'
  })

  switch (tabType) {
    case ComponentEditorTabType.PROPERTIES:
      return (
        <ComponentParameterWizardContainer
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          onEdit={onEdit}
          onBlur={onBlur}
        />
      )
    case ComponentEditorTabType.SQL:
      return (
        <div className={classes.ComponentSQLViewer}>
          <SQLViewer />
        </div>
      )
    case ComponentEditorTabType.POSTPROCESSING:
      return (
        <ComponentPostProcessingContainer
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
        />
      )
    default:
      return <EmptyPanel> {t('notAvailable')} </EmptyPanel>
  }
}
