import { useRef, useState, type KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Typography } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { useFetchComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { ReactComponent as IteratorIcon } from 'assets/iterator.svg'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { IconButton } from 'components/IconButton/IconButton'

import { useAvailableComponents } from 'hooks/useAvailableComponents'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useFlags } from 'hooks/useFlags'

import {
  useMakeComponent,
  type SourceComponentConnection
} from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'
import { OutputPortType } from 'job-lib/types/Components'
import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'

import { ComponentName } from 'modules/ComponentSummaries/components/ComponentName'
import { useWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import nodeToolbarClasses from '../ComponentNodeToolbar.module.scss'
import classes from './IterateButton.module.scss'

export const useAddIterateComponent = ({
  instanceId,
  componentInstanceName,
  position
}: {
  instanceId: ComponentInstanceId
  componentInstanceName: string
  position: {
    x: number
    y: number
  }
}) => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const [makeComponent] = useMakeComponent()
  const update = useWorkingCopy((state) => state.update)
  const dispatch = useDispatch()
  const launchDarkly = useLDClient()
  const fetchComponentMetadata = useFetchComponentMetadata()
  const { metadata: instanceMetadata } =
    useComponentInstanceMetadataQuery(instanceId)

  const addComponent = async (
    iteratorSummaryId: ComponentSummaryId,
    iteratorName: string,
    metadata: ComponentMetadata
  ) => {
    launchDarkly.track('Designer - Canvas - Add Iterator')
    const instanceInputPortCardinality =
      instanceMetadata?.inputPorts?.[0]?.cardinality

    if (!metadata?.outputPorts || !instanceInputPortCardinality) {
      console.error('Unable to determine port cardinality')

      return
    }

    const iteratorOutputPort = OutputPortType.ITERATION
    const iteratorOutputCardinality = metadata.outputPorts.find(
      (i) => i.portId === iteratorOutputPort
    )?.cardinality

    if (!iteratorOutputCardinality) {
      console.error('Unable to determine port cardinality')

      return
    }

    const sourceComponentConnection: SourceComponentConnection = {
      targetComponentId: instanceId,
      targetCardinality: instanceInputPortCardinality,
      targetComponentName: componentInstanceName,
      sourceCardinality: iteratorOutputCardinality,
      sourceType: iteratorOutputPort
    }

    if (rolloutEnableWorkingCopyProvider) {
      update((state) => {
        state.addComponent({
          componentId: iteratorSummaryId,
          componentName: iteratorName,
          componentMetadata: metadata,
          sourceComponentConnection,
          componentDesign: {
            position
          }
        })
      })

      return
    }

    const newComponent = await makeComponent({
      id: iteratorSummaryId,
      sourceComponentConnection,
      ...position,
      componentName: iteratorName
    })

    dispatch(jobActions.addComponent(newComponent))
  }

  const onSelectComponent = async (
    iteratorSummaryId: ComponentSummaryId,
    iteratorName: string
  ) => {
    const { metadata } = await fetchComponentMetadata(iteratorSummaryId)

    await addComponent(iteratorSummaryId, iteratorName, metadata)
  }

  return onSelectComponent
}

export const IterateButton = ({
  componentInstance
}: {
  componentInstance: ComponentInstance
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iterateModal' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { components } = useAvailableComponents()
  const componentInstanceName = getComponentName(componentInstance)
  const onSelectComponent = useAddIterateComponent({
    instanceId: componentInstance.id,
    componentInstanceName,
    position: {
      x: componentInstance.x,
      // height offset for stacked iterator
      y: componentInstance.y - 10
    }
  })

  const buttonRefs = useRef<Array<HTMLButtonElement | null>>([])

  const iteratorComponents = components
    .filter((component) => {
      return component.outputPorts.some(
        (value) => value.portId === OutputPortType.ITERATION
      )
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName))

  const onKeyDown = (e: KeyboardEvent, buttonIndex: number) => {
    e.stopPropagation()

    const maxIndex = iteratorComponents.length - 1
    const isLastComponent = buttonIndex === maxIndex
    const isFirstComponent = buttonIndex === 0

    const getNextComponent = () => {
      if (isLastComponent) {
        return 0
      }

      return buttonIndex + 1
    }

    const getPrevComponent = () => {
      if (isFirstComponent) {
        return maxIndex
      }

      return buttonIndex - 1
    }

    const focusButton = (index: number) => {
      const buttonElement = buttonRefs.current[index]

      /* istanbul ignore else */
      if (buttonElement !== null) {
        buttonElement.focus()
      }
    }

    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      e.preventDefault()
      focusButton(getNextComponent())
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      e.preventDefault()
      focusButton(getPrevComponent())
    }

    if (e.key === 'Home') {
      focusButton(0)
    }

    if (e.key === 'End') {
      focusButton(maxIndex)
    }
  }

  const onClick = () => {
    setIsModalOpen(true)
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <IconButton
        className={nodeToolbarClasses.Toolbar__Button}
        data-testid="btn-iterate"
        label={t('btnText')}
        onClick={onClick}
      >
        <IteratorIcon />
      </IconButton>

      {isModalOpen && (
        <DesignerModal
          data-testid="iterate-modal"
          className={classes.IterateModal}
          onCancel={onCancel}
          ariaLabelledBy="modal-title"
          size="mid"
        >
          <Typography
            className={classes.IterateModal__Title}
            as="h2"
            format="tm"
            id="modal-title"
          >
            {t('title')}
          </Typography>
          <span id="gridLabel" className="u-visually-hidden">
            {t('gridLabel')}
          </span>

          <div
            className={classes.IterateModal__Content}
            role="grid"
            aria-labelledby="gridLabel"
          >
            {iteratorComponents.map((component, index) => (
              <div key={component.componentId} role="row">
                <div className={classes.IterateItem} role="gridcell">
                  <button
                    ref={(el) => (buttonRefs.current[index] = el)}
                    data-testid="btn-iterate-item"
                    className={classes.IterateItem__Button}
                    type="button"
                    onKeyDown={(e: KeyboardEvent) => {
                      onKeyDown(e, index)
                    }}
                    onClick={() => {
                      onSelectComponent(
                        component.componentId,
                        component.displayName
                      )
                    }}
                  >
                    <div
                      className={classes.IterateItem__ImageWrapper}
                      data-testid="iterate-modal-icon"
                    >
                      <img
                        role="presentation"
                        src={component.icon}
                        alt={component.displayName}
                      />
                    </div>
                    <ComponentName
                      displayName={component.displayName}
                      searchTerm=""
                      isDraggable={false}
                    ></ComponentName>
                    <span
                      data-testid="iterate-tag"
                      className={classes.IterateItem__Tag}
                    >
                      {component.tags[0]}
                    </span>
                    <span
                      data-testid="iterate-description"
                      className={classes.IterateItem__Description}
                    >
                      {component.description}
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </DesignerModal>
      )}
    </>
  )
}
