import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useExportPipelines } from 'api/hooks/useExportPipelines/useExportPipelines'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import {
  FileTreeReducerType,
  type FileNode
} from '../../reducers/fileTreeReducer/types'
import CreatePipelineContextMenuEntries from '../CreatePipelineContextMenuEntries/CreatePipelineContextMenuEntries'
import { FileBrowserContext } from '../FileBrowserTree/context'
import { nodeIsEmpty } from '../FileTree/FileTree'

export interface FolderItemContextMenuProps {
  path: string
  handleImportPipelines: (e: React.MouseEvent<HTMLButtonElement>) => void
  isEmpty?: boolean
  node: FileNode | 'root'
}

const FolderItemContextMenu = ({
  path,
  node,
  handleImportPipelines,
  isEmpty = false
}: FolderItemContextMenuProps) => {
  const { t } = useTranslation()
  const { enableImportExportPipelines } = useFlags()
  const { branchId, projectId } = useProjectInfo()
  const { projectName } = useProjectNames()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { mutate: exportPipelines } = useExportPipelines('folder')
  const isRootFolder = node === 'root'
  const { enableCopyPastePipelines } = useFlags()

  const { copiedFileNode, handleCopyFile, setCopiedFileNode } =
    useContext(FileBrowserContext)

  const { hasPermission: canDelete } = useProjectPermission(
    'delete_pipelines',
    PermissionType.PROJECT
  )

  const { hasPermission: canCreate } = useProjectPermission(
    'create_pipelines',
    PermissionType.PROJECT
  )

  const { hasPermission: canUpdate } = useProjectPermission(
    'update_pipelines',
    PermissionType.PROJECT
  )

  const noFileIsCopied = copiedFileNode === undefined

  const handleCreateFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()

    onFileTreeCommand({
      type: FileTreeReducerType.CREATE_FOLDER,
      fileDetails: { path }
    })
  }

  const handleDeleteFolder = () => {
    onFileTreeCommand({
      type: FileTreeReducerType.DELETE_FOLDER,
      branch: branchId,
      project: projectId,
      fileDetails: { path }
    })
  }

  const handleExportFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    exportPipelines({
      paths: [path],
      fileName: `${projectName}_${branchId}_${path.replace(
        /\//g,
        '_'
      )}_${new Date().toISOString()}.zip`
    })
  }

  const handleCopyFileNode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    targetNode: FileNode
  ) => {
    e.stopPropagation()
    setCopiedFileNode(targetNode)
  }

  const handlePasteFileEvent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    targetNode: FileNode
  ) => {
    e.stopPropagation()

    if (targetNode.type === 'folder' && nodeIsEmpty(targetNode)) {
      onFileTreeCommand({
        type: FileTreeReducerType.COPY_EMPTY_FOLDER,
        branch: branchId,
        project: projectId,
        fileDetails: { path: targetNode.path },
        targetDirectory: path
      })
      return
    }

    handleCopyFile(targetNode.path, path)
  }

  return (
    <PopOverSection>
      <CreatePipelineContextMenuEntries
        folderPath={path}
        entryContext="folder"
      />

      <PopOverItem
        disabled={!canCreate}
        onClick={handleCreateFolder}
        data-testid="folder-context-menu-create-folder"
      >
        {t('create-folder.context-menu.option')}
      </PopOverItem>

      {!isRootFolder && (
        <PopOverItem
          disabled={!canDelete}
          onClick={handleDeleteFolder}
          data-testid="folder-context-menu-delete-folder"
        >
          {t('delete-folder.context-menu.option')}
        </PopOverItem>
      )}

      {enableImportExportPipelines && (
        <>
          {!isEmpty && (
            <PopOverItem onClick={handleExportFolder}>
              {t('export-folder.context-menu.option')}
            </PopOverItem>
          )}
          <PopOverItem disabled={!canCreate} onClick={handleImportPipelines}>
            {t('import-to-folder.context-menu.option')}
          </PopOverItem>
        </>
      )}

      {!isRootFolder && enableCopyPastePipelines && (
        <PopOverItem
          onClick={(e) => {
            handleCopyFileNode(e, node)
          }}
          data-testid="folder-context-menu-copy"
          disabled={!canUpdate}
        >
          {t('translation:jobContextMenu.copyJob')}
        </PopOverItem>
      )}

      {enableCopyPastePipelines && (
        <PopOverItem
          onClick={(e) => {
            handlePasteFileEvent(e, copiedFileNode as FileNode)
          }}
          data-testid="folder-context-menu-paste"
          disabled={noFileIsCopied || !canUpdate}
        >
          {t('translation:jobContextMenu.pasteJob')}
        </PopOverItem>
      )}
    </PopOverSection>
  )
}

export default FolderItemContextMenu
