import { useCallback, useMemo, type PropsWithChildren } from 'react'

import {
  GitContextProvider,
  GitEventProvider,
  GitQueryClientContext,
  type WorkingTreeRefreshEvent
} from '@matillion/git-component-library'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import useListBranches from './api/hooks/useListBranches'
import { useListEnvironments } from './api/hooks/useListEnvironments'
import { useFetchSharedPipelinesConfig } from './api/hooks/useSharePipeline/useSharePipeline'
import { useDoPublishJobs } from './hooks/usePublishJobsAction/usePublishJobsAction'
import useRefreshPipelines from './modules/core/EtlDesigner/hooks/useRefreshPipelines'

export const GitLibraryProvider = ({ children }: PropsWithChildren) => {
  const { data: environmentsResponse } = useListEnvironments()
  const { refreshPipelines } = useRefreshPipelines()
  const { doPublish } = useDoPublishJobs()
  const { refetch: refetchSharedPipelinesConfig } =
    useFetchSharedPipelinesConfig(false)

  const branchesQueryResult = useListBranches()

  const environments = useMemo(
    () =>
      environmentsResponse?.results.map((response) => ({
        id: response.id,
        name: response.name
      })) ?? [],
    [environmentsResponse?.results]
  )

  /**
   * When a server-side WTS state mutation occurs, we currently
   * must refresh pipelines to ensure the file-browser reflects
   * the current state of the server-side local repo in the WTS.
   *
   * If both events in {@link WorkingTreeRefreshEvent} are true,
   * it means either a commit, pull, merge or branch reset was performed.
   * In these cases we need to refresh the shared pipelines config contents.
   */
  const handleWTSRefresh = useCallback(
    async (e: WorkingTreeRefreshEvent) => {
      await refreshPipelines(e)

      if (e.refreshFileContents && e.refreshFileSummaries) {
        await refetchSharedPipelinesConfig()
      }
    },
    [refreshPipelines, refetchSharedPipelinesConfig]
  )

  return (
    <GitEventProvider
      onRefreshWorkingTreeStatus={handleWTSRefresh}
      onPublishPipelines={doPublish}
    >
      <GitContextProvider
        environments={environments}
        branchesQueryResult={branchesQueryResult}
      >
        <>
          {children}
          {process.env.REACT_APP_REACT_GCL_QUERY_DEVTOOL === 'true' && (
            <section
              aria-label="React Query GCL Devtools"
              data-testid="gcl-react-query-devtools"
            >
              <ReactQueryDevtools context={GitQueryClientContext} />
            </section>
          )}
        </>
      </GitContextProvider>
    </GitEventProvider>
  )
}
