import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { JobType } from 'job-lib/types/JobType'

import { jobActions } from '../../job.slice'
import {
  type JobState,
  type JobStateOrchestration,
  type UpdateComponentPosition
} from '../../job.types'
import { removeOrchestrationLinks } from '../deleteNodes/utils/removeOrchestrationLinks'
import { updateNodePosition } from '../updateNodePosition/updateNodePosition'

interface DetachIteratorPayload {
  iteratorInstanceId: number
}

export const detachIterator: CaseReducer<
  JobState,
  PayloadAction<DetachIteratorPayload>
> = (state, { payload: { iteratorInstanceId } }) => {
  if (jobIsOrchestration(state)) {
    if (!state.job.components[iteratorInstanceId]) {
      return
    }
    const component = state.job.components[iteratorInstanceId]

    const iterationConnectorId = component.outputIterationConnectorIDs.at(0)

    if (iterationConnectorId === undefined) {
      return
    }

    const linkTarget = state.job.iterationConnectors[iterationConnectorId]

    if (!linkTarget) {
      return
    }
    const baseComponentInstanceId = linkTarget.targetID
    removeOrchestrationLinks({
      job: state.job,
      componentInstanceIds: [iteratorInstanceId]
    })
    // the base component must be moved rather the iterator, as once the connection is
    // made, the position of the joined component is clamped to the iterator, and is
    // incorrect on the base component
    const updateNodePositionAction: UpdateComponentPosition = {
      id: baseComponentInstanceId,
      type: 'component',
      x: state.job.components[iteratorInstanceId].x,
      y: state.job.components[iteratorInstanceId].y + 100
    }
    updateNodePosition(
      state,
      jobActions.updateNodePosition(updateNodePositionAction)
    )
  }
}

function jobIsOrchestration(job: JobState): job is JobStateOrchestration {
  return job?.jobType === JobType.Orchestration
}
