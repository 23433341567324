import { type FC } from 'react'

import { ComponentNameParameter } from './components/ComponentNameParameter'
import { ComponentParameterTabs } from './components/ComponentParameterTabs/ComponentParameterTabs'
import { ComponentSupersededWarning } from './components/ComponentSupersededWarning/ComponentSupersededWarning'
import { type ComponentParametersWizardProps } from './types'

const ComponentParametersContent: FC<ComponentParametersWizardProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  return (
    <>
      <ComponentNameParameter
        componentInstance={componentInstance}
        componentSummaryId={componentSummaryId}
        componentMetadata={componentMetadata}
        onEdit={onEdit}
        onBlur={onBlur}
      />
      <ComponentSupersededWarning
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
      />
      <ComponentParameterTabs
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
        onEdit={onEdit}
        onBlur={onBlur}
      />
    </>
  )
}

export default ComponentParametersContent
