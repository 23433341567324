import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Icon } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import { useCopyComponent } from 'file-editors/canvas/modules/CopyPasteComponent/useCopyComponent'

import { ReactComponent as CopyIcon } from 'assets/copy.svg'
import { ReactComponent as DetachIcon } from 'assets/detach.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import useCanRunComponent from 'hooks/useCanRunComponent/useCanRunComponent'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useFlags } from 'hooks/useFlags'

import {
  startImplementationId,
  unknownOrchestrationImplementationId,
  unknownTransformationImplementationId
} from 'job-lib/cisIds/knownComponentParameters'
import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { useDetachIterator } from 'job-lib/hooks/useDetachIterator/useDetachIterator'
import { type RootState } from 'job-lib/store'
import { ConnectionPortType } from 'job-lib/types/Components'
import { type ComponentInstance } from 'job-lib/types/Job'

import { getComponentSelector } from 'modules/core/EtlDesigner/selectors/componentSelector'
import { singleStartComponentSelector } from 'modules/core/EtlDesigner/selectors/singleStartComponentSelector'

import classes from './ComponentNodeToolbar.module.scss'
import { IterateButton } from './components/IterateButton'
import RunFromButton from './components/RunFromComponentButton'
import RunOnlyButton from './components/RunOnlyComponentButton'

interface ComponentNodeToolbarProps {
  summaryId: string
  componentInstanceId: number
  attachedIterator?: {
    id: number
  }
}

export const ComponentNodeToolbar = ({
  summaryId,
  componentInstanceId,
  attachedIterator
}: ComponentNodeToolbarProps) => {
  const { metadata } = useComponentInstanceMetadataQuery(componentInstanceId)
  const { pipelineSummary } = useActivePipelineSummary()
  const { enableImprovedCanvasInteractions } = useFlags()
  const jobId = pipelineSummary?.name ?? ''
  const fileType = pipelineSummary?.type ?? undefined
  const { copyComponent } = useCopyComponent()
  const { deleteNodes } = useDeleteNodes()
  const { detachIterator: detach } = useDetachIterator()
  const hasSingleStartComponent = useSelector<RootState, boolean>(
    singleStartComponentSelector
  )
  const component = useSelector<RootState, ComponentInstance | null>(
    getComponentSelector(componentInstanceId)
  )

  const { canRunFromComponent, canRunOnlyComponent } = useCanRunComponent(
    componentInstanceId,
    summaryId
  )
  const { t } = useTranslation()
  const { enableDetachIterator } = useFlags()

  if (!component) {
    return null
  }

  const isUnknown =
    component.implementationID === unknownOrchestrationImplementationId ||
    component.implementationID === unknownTransformationImplementationId

  const isSingleStartComponent =
    component.implementationID === startImplementationId &&
    hasSingleStartComponent

  const isIterator = metadata?.outputPorts?.some(
    (port) => port.portId === ConnectionPortType.ITERATION
  )

  const hasInputPorts = !!metadata?.inputPorts?.length
  const isOrchestration = fileType === FileType.ORCHESTRATION_PIPELINE
  const isIteratorAttached =
    'inputIterationConnectorIDs' in component &&
    component.inputIterationConnectorIDs.length > 0

  return (
    <div
      data-testid={`component-${componentInstanceId}-toolbar`}
      className={classes.Toolbar}
    >
      {isOrchestration && canRunOnlyComponent && (
        <RunOnlyButton jobId={jobId} />
      )}
      {isOrchestration && (
        <RunFromButton
          jobId={jobId}
          summaryId={summaryId}
          canRunFrom={canRunFromComponent}
        />
      )}
      <IconButton
        disabled={isUnknown}
        onClick={() => {
          copyComponent()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.copyComponent')}
      >
        <CopyIcon />
      </IconButton>
      {enableDetachIterator && attachedIterator && (
        <IconButton
          onClick={() => {
            detach(attachedIterator.id)
          }}
          className={classes.Toolbar__Button}
          label={t('componentToolbox.detachComponent')}
        >
          <DetachIcon />
        </IconButton>
      )}
      {hasInputPorts &&
        !isUnknown &&
        isOrchestration &&
        !isIterator &&
        !isIteratorAttached &&
        enableImprovedCanvasInteractions && (
          <IterateButton componentInstance={component} />
        )}
      <IconButton
        disabled={isSingleStartComponent}
        onClick={() => {
          deleteNodes()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.deleteComponent')}
      >
        <Icon.Trash />
      </IconButton>
    </div>
  )
}
