/* eslint-disable @typescript-eslint/no-dynamic-delete */

import type { Pipeline } from 'types/Pipeline'

import { deleteDanglingLinks } from '../deleteComponents/deleteComponents'
import { updateComponentPosition } from '../updateComponentPosition/updateComponentPosition'

export const detachIterator = (state: Pipeline) => (iteratorName: string) => {
  if (!Object.hasOwn(state.pipeline.components, iteratorName)) {
    return
  }
  const iterationTarget =
    state.pipeline.components[iteratorName].iterationTarget
  delete state.pipeline.components[iteratorName].iterationTarget
  delete state.pipeline.components[iteratorName].transitions
  deleteDanglingLinks(state, iteratorName)

  if (iterationTarget !== undefined) {
    const currentPosition = state.design.components[iteratorName].position
    updateComponentPosition(state)({
      name: iterationTarget,
      position: { x: currentPosition.x, y: currentPosition.y + 100 }
    })
  }
}
