import { useCallback } from 'react'

import { type ComponentNodeData } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import { useUpdateLinks } from 'job-lib/store/jobSlice/hooks/useUpdateLinks'
import {
  ConnectionPortType,
  type OutputPortType
} from 'job-lib/types/Components'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { getIdFromReactFlowId } from '../../../../hooks/useCanvasModel/utils'
import { useSyncedCanvasModel } from '../useSyncedCanvasModel'

export const useSwitchConnectionType = (
  pipeline: TransformationJob | OrchestrationJob
) => {
  const { syncCanvasModel } = useSyncedCanvasModel(pipeline)
  const updateLinks = useUpdateLinks()
  const { invalidateComponent } = useComponentValidationProvider()

  const switchConnectionType = useCallback(
    (
      sourceNodeId: string,
      targetNodeId: string,
      sourceComponentNode: ComponentNodeData,
      targetComponentNode: ComponentNodeData,
      sourceType: OutputPortType
    ) => {
      const sourceId = Number.parseInt(getIdFromReactFlowId(sourceNodeId))
      const targetId = Number.parseInt(getIdFromReactFlowId(targetNodeId))

      const sourcePort = (
        sourceType === ConnectionPortType.ITERATION
          ? sourceComponentNode.iteratorPorts
          : sourceComponentNode.outputPorts
      ).find(({ portId }) => portId === sourceType)

      const targetPort = targetComponentNode.inputPorts.find(
        ({ portId }) => portId === ConnectionPortType.INPUT
      )

      if (!sourcePort || !targetPort) {
        return
      }

      updateLinks({
        sourceComponentId: sourceId,
        targetComponentId: targetId,
        sourceCardinality: sourcePort.cardinality,
        targetCardinality: targetPort.cardinality,
        sourceType
      })

      syncCanvasModel()
      invalidateComponent(targetId)
    },
    [syncCanvasModel, updateLinks, invalidateComponent]
  )

  return { switchConnectionType }
}
